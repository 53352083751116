// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper,
.react-datepicker__input-container,
.MuiInputBase-root {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Lenders/Lender/Sections/basicDetails.css"],"names":[],"mappings":"AAAA;;;EAGE,YAAY;AACd","sourcesContent":[".react-datepicker-wrapper,\n.react-datepicker__input-container,\n.MuiInputBase-root {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
